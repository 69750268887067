import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { black, white } from '@/style-guide/colors'
import { Button, Card } from '@/style-guide'

const CardContainer = styled(Card)`
  width: 100%;
  height: fit-content;
  margin-top: 30px;
  margin-right: 15px;
  padding: 40px;

  ${media.greaterThan('medium')`
    padding: 55px 67px;
    width: 35%;
    min-width: 392px;
  `}
`

const Title = styled.span`
  font-size: 21px;
  line-height: 23px;
  color: ${black};
`

const Text = styled.div`
  font-size: 14px;
  line-height: 24px;
  margin-top: 10px;

  color: #7f92a3;
`

const BtnCareers = styled(Button)`
  background: linear-gradient(36.35deg, #4371dd 31.79%, #92e7f2 97.12%);
  margin-top: 60px;
  width: 100%;
  max-width: unset;

  :hover:not(:disabled) {
    opacity: 0.8;
  }

  :active:not(:disabled) {
    opacity: 0.7;
  }

  ${media.greaterThan('medium')`
    width: 176px;
  `}
`

const Label = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: ${white};
  text-transform: capitalize;
`

const CardJointTeam = () => (
  <CardContainer
    styleContent={{ padding: 0 }}
    content={
      <>
        <Title>Join the team</Title>
        <Text>
          We’re a group of highly motivated people with the goal to help millions. All we’re missing
          is you :-)
        </Text>
        <BtnCareers label={<Label>See Careers</Label>} onClick={() => {}} />
      </>
    }
  />
)

export default CardJointTeam
