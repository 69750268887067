import React from 'react'
import { Link as LinkComponent } from 'gatsby'
import styled from 'styled-components'
import media from 'styled-media-query'

import { black, white, marineBlue, orange } from '@/style-guide/colors'
import { Button, Card, Input as InputComponent, TextArea as TextAreaComponent } from '@/style-guide'

const CardContainer = styled(Card)`
  width: 100%;
  height: fit-content;
  margin-top: 30px;
  padding: 40px;

  ${media.greaterThan('medium')`
    padding: 55px 26px 13px 26px;
    width: 65%;
    margin-left: 15px;
    min-width: 503px;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Title = styled.span`
  font-size: 21px;
  line-height: 23px;
  color: ${black};
`

const BtnSubmit = styled(Button)`
  margin-top: 20px;
  width: 100%;
  max-width: unset;
`

const Footer = styled.span`
  font-size: 12px;
  line-height: 29px;
  color: ${marineBlue};
  text-align: center;
  text-transform: capitalize;
  margin-top: 15px;
`

const Label = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  color: ${white};
  text-transform: capitalize;
`

const Input = styled(InputComponent)`
  margin-top: 20px;
  padding: 10px;
  font-weight: normal;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`

const TextArea = styled(TextAreaComponent)`
  margin-top: 20px;
  padding: 10px;
  font-weight: normal;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  height: 203px;
`

const Link = styled(LinkComponent)`
  color: ${orange};
`

const commomProps = {
  styleWrapper: { border: 'unset' },
  style: { fontWeight: 'normal', borderRadius: 4, backgroundColor: white, fontFamily: 'Poppins' },
  styleLabel: { fontSize: 16, lineHeight: 29 }
}

const CardForm = () => (
  <CardContainer
    styleContent={{ padding: 0 }}
    content={
      <Content>
        <Title>Send Message</Title>
        <Input {...commomProps} placeholder="Name" />
        <Input {...commomProps} placeholder="E-mail" />
        <TextArea {...commomProps} placeholder="Your questions" />
        <BtnSubmit label={<Label>Send</Label>} onClick={() => {}} />
        <Footer>
          By clicking &quot;Send&quot; I agree to calculatex{' '}
          <Link to="/contact">Term of Service and Privacy Policy</Link>
        </Footer>
      </Content>
    }
  />
)

export default CardForm
