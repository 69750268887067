import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { Card } from '@/style-guide'

const CardContainer = styled(Card)`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 40px;

  ${media.greaterThan('medium')`
    min-width: 925px;
    justify-content: space-between;
    flex-direction: row;
    padding: 55px 67px;
  `}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > * {
    margin-top: 20px;
  }

  ${media.greaterThan('medium')`
    flex-direction: row;
    justify-content: space-between;

    > * {
    margin-top: 0px;
  }
  `}
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: capitalize;
  color: #4472de;
`

const Text = styled.span`
  font-size: 14px;
  line-height: 24px;
  color: #7f92a3;
`

const Informative = () => (
  <CardContainer
    styleContent={{ display: 'flex', justifyContent: 'space-between', padding: 0 }}
    content={
      <Content>
        <Section>
          <Title>Contact Us</Title>
          <Text>774-421-9978</Text>
          <Text>hello@calculatex.com</Text>
        </Section>
        <Section>
          <Title>Address</Title>
          <Text>Staylime Inc. </Text>
          <Text>541 Jefferson Ave, Suite 100 </Text>
          <Text>Redwood City, CA 94063</Text>
        </Section>
        <Section>
          <Title>Feedback</Title>
          <Text>Help us improve the site.</Text>
          <Text>feedback@calculatex.com</Text>
        </Section>
      </Content>
    }
  />
)

export default Informative
