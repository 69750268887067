import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import { white } from '@/style-guide/colors'

import Layout, { TopBar, Footer } from '@/components/Layout'

import SEO from '@/components/seo'

import Header from './Header'
import Informative from './Informative'
import CardJoinTeam from './CardJoinTeam'
import CardForm from './CardForm'

const Container = styled.div`
  background-color: ${white};
  height: 100vh;
  width: 100%;
`

const Content = styled.div`
  position: relative;
  bottom: 230px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 32px;
  margin-bottom: -230px;

  ${media.greaterThan('medium')`
    padding: 60px 160px 40px 160px;
  `}
`

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${media.greaterThan('medium')`
    flex-direction: row;
  `}
`

const ContactUs = () => (
  <>
    <SEO title="Contact Us" />
    <Layout>
      <Container>
        <TopBar />
        <Header />
        <Content>
          <Informative />
          <Section>
            <CardJoinTeam />
            <CardForm />
          </Section>
        </Content>
        <Footer />
      </Container>
    </Layout>
  </>
)

export default ContactUs
